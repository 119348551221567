import "./components/About";
import "./assets/css/main.css";
import { toggleDarkMode } from "./assets/js/darkmode.js";

function App() {
  return (
    <div className="App" onDoubleClick={toggleDarkMode}>
      <div id="wrapper" class="fade-in" className="wrapper">
        <div id="intro">
          <p>A Website under Construction...</p>
          <ul class="actions" id="actions">
            <li>
              <a
                id="button"
                href="#header"
                class="button icon solid solo fa-arrow-down scrolly"
              ></a>
            </li>
          </ul>
        </div>

        <header id="header" class="header">
          <a href="http://shabib.ddnss.eu/admin/index.php?login" class="logo">
            Admin Panel
          </a>
        </header>

        <nav id="nav">
          <ul class="links">
            <li class="active">
              <a id="nav_1" href="App.js">
                This is Massively
              </a>
            </li>
          </ul>
          <ul class="icons">
            <li>
              <a
                id="insta"
                target="_blank"
                href="https://www.instagram.com/maximilian.hxrn"
                class="icon brands fa-instagram"
                rel="noreferrer"
              >
                <span class="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                id="github_icon"
                target="_blank"
                href="https://github.com/shabib309"
                class="icon brands fa-github"
                rel="noreferrer"
              >
                <span class="label">GitHub</span>
              </a>
            </li>
          </ul>
        </nav>

        <div id="main" className="main">
          <article class="post featured">
            <header class="major">
              <span class="date">April 25, 2017</span>
              <h2>
                <a href="#" class="text">
                  And this is a<br />
                  massive headline
                </a>
              </h2>
              <p>
                Aenean ornare velit lacus varius enim ullamcorper proin aliquam
                <br /> facilisis ante sed etiam magna interdum congue. Lorem
                ipsum dolor
                <br /> amet nullam sed etiam veroeros.
              </p>
            </header>
            <a href="#" class="image main">
              <img src="images/pic01.jpg" alt="" />
            </a>
            <ul class="actions special">
              <li>
                <a href="#" class="button large">
                  Full Story
                </a>
              </li>
            </ul>
          </article>

          <section class="posts">
            <article>
              <header>
                <span class="date">April 24, 2017</span>
                <h2>
                  <a href="#">
                    Sed magna
                    <br />
                    ipsum faucibus
                  </a>
                </h2>
              </header>
              <a href="#" class="image fit">
                <img src="images/pic02.jpg" alt="" />
              </a>
              <p>
                Donec eget ex magna. Interdum et malesuada fames ac ante ipsum
                primis in faucibus. Pellentesque venenatis dolor imperdiet dolor
                mattis sagittis magna etiam.
              </p>
              <ul class="actions special">
                <li>
                  <a href="#" class="button">
                    Full Story
                  </a>
                </li>
              </ul>
            </article>
            <article>
              <header>
                <span class="date">April 22, 2017</span>
                <h2>
                  <a href="#">
                    Primis eget
                    <br />
                    imperdiet lorem
                  </a>
                </h2>
              </header>
              <a href="#" class="image fit">
                <img src="images/pic03.jpg" alt="" />
              </a>
              <p>
                Donec eget ex magna. Interdum et malesuada fames ac ante ipsum
                primis in faucibus. Pellentesque venenatis dolor imperdiet dolor
                mattis sagittis magna etiam.
              </p>
              <ul class="actions special">
                <li>
                  <a href="#" class="button">
                    Full Story
                  </a>
                </li>
              </ul>
            </article>
            <article>
              <header>
                <span class="date">April 18, 2017</span>
                <h2>
                  <a href="#">
                    Ante mattis
                    <br />
                    interdum dolor
                  </a>
                </h2>
              </header>
              <a href="#" class="image fit">
                <img src="images/pic04.jpg" alt="" />
              </a>
              <p>
                Donec eget ex magna. Interdum et malesuada fames ac ante ipsum
                primis in faucibus. Pellentesque venenatis dolor imperdiet dolor
                mattis sagittis magna etiam.
              </p>
              <ul class="actions special">
                <li>
                  <a href="#" class="button">
                    Full Story
                  </a>
                </li>
              </ul>
            </article>
            <article>
              <header>
                <span class="date">April 14, 2017</span>
                <h2>
                  <a href="#">
                    Tempus sed
                    <br />
                    nulla imperdiet
                  </a>
                </h2>
              </header>
              <a href="#" class="image fit">
                <img src="images/pic05.jpg" alt="" />
              </a>
              <p>
                Donec eget ex magna. Interdum et malesuada fames ac ante ipsum
                primis in faucibus. Pellentesque venenatis dolor imperdiet dolor
                mattis sagittis magna etiam.
              </p>
              <ul class="actions special">
                <li>
                  <a href="#" class="button">
                    Full Story
                  </a>
                </li>
              </ul>
            </article>
            <article>
              <header>
                <span class="date">April 11, 2017</span>
                <h2>
                  <a href="#">
                    Odio magna
                    <br />
                    sed consectetur
                  </a>
                </h2>
              </header>
              <a href="#" class="image fit">
                <img src="images/pic06.jpg" alt="" />
              </a>
              <p>
                Donec eget ex magna. Interdum et malesuada fames ac ante ipsum
                primis in faucibus. Pellentesque venenatis dolor imperdiet dolor
                mattis sagittis magna etiam.
              </p>
              <ul class="actions special">
                <li>
                  <a href="#" class="button">
                    Full Story
                  </a>
                </li>
              </ul>
            </article>
            <article>
              <header>
                <span class="date">April 7, 2017</span>
                <h2>
                  <a href="#">
                    Augue lorem
                    <br />
                    primis vestibulum
                  </a>
                </h2>
              </header>
              <a href="#" class="image fit">
                <img src="images/pic07.jpg" alt="" />
              </a>
              <p>
                Donec eget ex magna. Interdum et malesuada fames ac ante ipsum
                primis in faucibus. Pellentesque venenatis dolor imperdiet dolor
                mattis sagittis magna etiam.
              </p>
              <ul class="actions special">
                <li>
                  <a href="#" class="button">
                    Full Story
                  </a>
                </li>
              </ul>
            </article>
          </section>

          <footer>
            <div class="pagination">
              <a id="active_nr" href="#" class="page active">
                1
              </a>
              <a href="#" class="page">
                2
              </a>
              <a href="#" class="page">
                3
              </a>
              <span class="extra">&hellip;</span>
              <a href="#" class="page">
                8
              </a>
              <a href="#" class="page">
                9
              </a>
              <a href="#" class="page">
                10
              </a>
              <a href="#" class="next">
                Next
              </a>
            </div>
          </footer>
        </div>

        <footer id="footer">
          <section>
            <form method="post" action="#">
              <div class="fields">
                <div class="field">
                  <label for="name">Name</label>
                  <input type="text" name="name" id="name" />
                </div>
                <div class="field">
                  <label for="email">Email</label>
                  <input type="text" name="email" id="email" />
                </div>
                <div class="field">
                  <label for="message">Message</label>
                  <textarea name="message" id="message" rows="3"></textarea>
                </div>
              </div>
              <ul class="actions">
                <li>
                  <input type="submit" value="Send Message" />
                </li>
              </ul>
            </form>
          </section>
          <section class="split contact">
            <section class="alt">
              <h3>Address</h3>
              <p>
                1234 Somewhere Road #87257
                <br /> Nashville, TN 00000-0000
              </p>
            </section>
            <section>
              <h3>Phone</h3>
              <p>
                <a href="#">(000) 000-0000</a>
              </p>
            </section>
            <section>
              <h3>Email</h3>
              <p>
                <a href="#">info@untitled.tld</a>
              </p>
            </section>
            <section>
              <h3>Social</h3>
              <ul class="icons alt">
                <li>
                  <a href="#" class="icon brands alt fa-twitter">
                    <span class="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="icon brands alt fa-facebook-f">
                    <span class="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="icon brands alt fa-instagram">
                    <span class="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="icon brands alt fa-github">
                    <span class="label">GitHub</span>
                  </a>
                </li>
              </ul>
            </section>
          </section>
        </footer>

        <div id="copyright">
          <ul>
            <li>Copyright &copy; 2022 by Maximilian Horn</li>
            <li>
              Design: <a href="https://github.com/shabib309">Maximilian Horn</a>
            </li>
          </ul>
        </div>
      </div>
      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/jquery.scrollex.min.js"></script>
      <script src="assets/js/jquery.scrolly.min.js"></script>
      <script src="assets/js/browser.min.js"></script>
      <script src="assets/js/breakpoints.min.js"></script>
      <script src="assets/js/util.js"></script>
      <script src="assets/js/main.js"></script>
    </div>
  );
}

export default App;
