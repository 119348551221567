export function toggleDarkMode() {
  if (document.getElementById("main").style.backgroundColor === "white") {
    document.getElementById("active_nr").style.backgroundColor = "#2c2c2c";
    document.getElementById("main").style.backgroundColor = "#2c2c2c";
    document.getElementById("footer").style.backgroundColor = "#2c2c2c";
    document.getElementById("main").style.color = "white";
    document
      .getElementById("header")
      .getElementsByClassName("logo")[0].style.borderColor = "#2c2c2c";
    document
      .getElementById("header")
      .getElementsByClassName("logo")[0].style.color = "black";
    document
      .getElementById("nav_1")
      .setAttribute("style", "background-color: #2c2c2c");
    document.getElementById("nav_1").setAttribute("style", "color: black");
    document.getElementById("insta").setAttribute("style", "color: black");
    document.getElementById("intro").setAttribute("style", "color: black");
    document
      .getElementById("github_icon")
      .setAttribute("style", "color: black");
    document
      .getElementById("button")
      .setAttribute("style", "box-shadow: inset 0 0 0 2px #000000");
  } else {
    document.getElementById("main").style.backgroundColor = "white";
    document.getElementById("active_nr").style.backgroundColor = "white";
    document.getElementById("footer").style.backgroundColor = "white";
    document.getElementById("main").style.color = "black";
    document
      .getElementById("header")
      .getElementsByClassName("logo")[0].style.borderColor = "white";
    document
      .getElementById("header")
      .getElementsByClassName("logo")[0].style.color = "white";
    document
      .getElementById("nav_1")
      .setAttribute("style", "background-color: white");
    document.getElementById("nav_1").setAttribute("style", "color: white");
    document.getElementById("insta").setAttribute("style", "color: white");
    document
      .getElementById("github_icon")
      .setAttribute("style", "color: white");
    document.getElementById("intro").setAttribute("style", "color: white");
    document
      .getElementById("button")
      .setAttribute("style", "box-shadow: inset 0 0 0 2px #ffffff");
  }
}
